import axios from 'axios';
import * as qs from 'qs';
import RequestVerificationToken from "./RequestVerificationToken";
import { ExceptionModel } from '../6_Models/ExceptionModel';

export default class AccessorUtilities {
    public static numberOfAjaxCallsPending: number = 0;

    public static get<T>(url: string, params?: any): Promise<T> {
        return axios.get<T>(url, {
            params: { ...params },
            paramsSerializer: function (params) {
                return qs.stringify(params, { indices: false })
            }
        }).then(response => response.data);
    };

    public static post<S>(url: string, data?: any): Promise<S> {
        return axios.post<S>(url, data, {
            headers: {
                "__RequestVerificationToken": RequestVerificationToken.getRequestVerificationTokenValue()
            }
        })
            .then(response => response.data)
            .catch((e) => {         
                return Promise.reject({ message: e.response.statusText, statusCode: e.response.status } as ExceptionModel);
            });
    };

    public static Initialize = (): void => {
        axios.interceptors.request.use(config => {
            AccessorUtilities.numberOfAjaxCallsPending++;
            return config;
        }, error => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(config => {
            if (AccessorUtilities.numberOfAjaxCallsPending > 0) AccessorUtilities.numberOfAjaxCallsPending--;
            return config;
        }, error => {
            if (AccessorUtilities.numberOfAjaxCallsPending > 0) AccessorUtilities.numberOfAjaxCallsPending--;
            return Promise.reject(error);
        });
    }
}

AccessorUtilities.Initialize();