import * as toastr from 'toastr';
import * as swal from '../../../node_modules/sweetalert2/dist/sweetalert2';

export default interface IAlertHelper {
    successToast(title: string, message: string): void;
    processingToast(title: string, message: string, promise: PromiseLike<any>): void;

    confirmationModal(title: string, message: string, confirmButtonText: string, cancelButtonText: string): Promise<boolean>;
    confirmationModalHtml(title: string, html: string, confirmButtonText: string, cancelButtonText: string): Promise<boolean>;

    errorModal(title: string, message: string): Promise<void>;
    errorModalHtml(title: string, html: string): Promise<void>;

    openProcessingModal(title: string, message: string): void;
    closeProcessingModal(): void;
}

export class AlertHelper implements IAlertHelper {
    successToast = (title: string, message: string): void => {
        toastr.success(message, title, { closeButton: true });
    }
    processingToast = (title: string, message: string, promise: PromiseLike<any>): void => {
        const toast = toastr.info(message, title, { extendedTimeOut: 0, timeOut: 0 });
        promise.then(
            () => {
                toast.hide();
            },
            () => {
                toast.hide();
            });
    }

    confirmationModal = (title: string, message: string, confirmButtonText: string, cancelButtonText: string): Promise<boolean> => {
        return swal({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        }).then((result) => {
            if (result.value) {
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        });
    }

    confirmationModalHtml = (title: string, html: string, confirmButtonText: string, cancelButtonText: string): Promise<boolean> => {
        return swal({
            title: title,
            html: html,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
        }).then((result) => {
            if (result.value) {
                return Promise.resolve(true);
            }
            return Promise.resolve(false);
        });
    }

    errorModal = (title: string, message: string): Promise<void> => {
        return swal({
            title: title,
            text: message,
            type: 'error'
        })
            .then(() => {
                return Promise.resolve();
            });
    }

    errorModalHtml = (title: string, html: string): Promise<void> => {
        return swal({
            title: title,
            html: html,
            type: 'error'
        })
            .then(() => {
                return Promise.resolve();
            });
    }

    openProcessingModal = (title: string, message: string): void => {
        swal.fire({
            title: title,
            text: message,
            type: 'info',
            allowOutsideClick: false,
            allowEscapeKey: false,
        });

        swal.showLoading();
    }

    closeProcessingModal = (): void => {
        swal.close();
    }
}