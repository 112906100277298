import * as $ from "jquery";

export default function initLogin() {
    $('form').submit(e => {
        $(e.target).find('button[type=submit]').prop('disabled', true);
    });
}

export function DuplicateCode() {
    $('form').submit(e => {
        $(e.target).find('button[type=submit]').prop('disabled', true);
    });
}