export interface SecureAjaxPostRequestOptions {
    url: string,
    data: any,
    success(response: any) : void,
    error(response: any) : void,
}

export default function (options: SecureAjaxPostRequestOptions) : PromiseLike<any> {
    let token = $('input[name="__RequestVerificationToken"]').first().val() as string;

    return $.ajax({
        url: options.url,
        headers: {
            "__RequestVerificationToken": token || ''
        },
        type: 'POST',
        data: JSON.stringify(options.data),
        success: options.success,
        error: options.error,
        dataType: 'json',
        contentType: 'application/json'
    });
};