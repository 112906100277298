/// <reference path="./Definitions/jquery.d.ts" />

import * as $ from 'jquery';
import AccessorUtilities from '../4_Accessors/AccessorUtilities';
import secureAjaxPostRequest, { SecureAjaxPostRequestOptions } from './Hacks/SecurePostRequestMethod';
import IAlertHelper, { AlertHelper } from './AlertHelper';
import { escapeHtml } from './HtmlHelpers';

export { };

interface IB2BPortalSiteGlobals {
    hasActiveAjaxRequests(): boolean;
    secureAjaxPostRequest(options: SecureAjaxPostRequestOptions): PromiseLike<any>;
    alertHelper: IAlertHelper;
    eProtect(): eProtect;
    clientId: number;
}

class B2BPortalSiteGlobals implements IB2BPortalSiteGlobals {
    constructor() {
        this.alertHelper = new AlertHelper();

        let temp: any = window;
        this.eProtect = temp.eProtect;
        this.clientId = temp.accountId;
    }

    hasActiveAjaxRequests = (): boolean => {
        return (AccessorUtilities.numberOfAjaxCallsPending + $.active) > 0;
    };

    secureAjaxPostRequest = (options: SecureAjaxPostRequestOptions): PromiseLike<any> => {
        return secureAjaxPostRequest(options);
    }

    public alertHelper: IAlertHelper;

    escapeHtml = (unsafe: string): string => {
        return escapeHtml(unsafe);
    }

    public eProtect: () => eProtect;

    clientId: number;
}

declare global {
    interface Window { B2BPortalSite: IB2BPortalSiteGlobals; }
}

window.B2BPortalSite = window.B2BPortalSite || new B2BPortalSiteGlobals();