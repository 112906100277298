import InitLogin from './1_UI/Pages/InitLogin';

$(() => {
    let currentPageNode = $("#currentPage");
    let currentPage = currentPageNode.length > 0 ? JSON.parse(currentPageNode.text()) as string : "";

    switch (currentPage) {
        case "login-page":
            InitLogin();
            break;
        default:
            break;
    }

});